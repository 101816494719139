@import 'styles/sizes';
@import 'styles/variables';

$breakpoint: 520px;

.questionsPage {
  .table {
    position: relative;
  }

  .table:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(180deg, $white-transparent, $white 95%);
    width: 100%;
    height: 180px;
  }

  .buttonAfterTable {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 8px;
    align-self: center;
    max-width: 220px;
    width: 100%;
    height: 56px;
    line-height: 1.4;

    @include tablet {
      max-width: 60%;
    }

    @include phone {
      max-width: 100%;
    }
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.testimonialsTitle {
  margin: 44px 0 20px;
  font-weight: bold;
  text-align: center;
}

